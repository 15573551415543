import React, { useMemo, useState } from "react";
import { IntlContext } from "./IntlContext";
import { defaultLang } from "../constants";

export const IntlProvider = ({ children }) => {
  const [lang, setLang] = useState(defaultLang);
  const [pageLangVariants, setPageLangVariants] = useState(null);

  const contextValue = useMemo(() => ({
    lang,
    setLang,
    pageLangVariants,
    setPageLangVariants,
  }), [lang, setLang, pageLangVariants, setPageLangVariants]);

  return (
    <IntlContext.Provider value={contextValue}>
      {children}
    </IntlContext.Provider>
  );
};
