/*
 * gatsby-browser.js
 * -----------------
 * This file is imported by the Gatsby entry level script. You should use this
 * file to include global imports, such global styles.
 */

import "./src/styles/index.scss";
import React from "react";
import { IntlProvider } from "./src/components/IntlProvider";

export const wrapRootElement = ({ element }) => (
  <IntlProvider>{element}</IntlProvider>
);
