module.exports = [{
      plugin: require('/home/root404/web-dev/apps/gatsbyjs/centroarredamentomasanta/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"linkImagesToOriginal":false,"quality":90,"withWebp":{"quality":95}},
    },{
      plugin: require('/home/root404/web-dev/apps/gatsbyjs/centroarredamentomasanta/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Questrial"],"urls":["https://fonts.googleapis.com/css?family=Questrial:100,200,300,400,500,600,700,900&text=0123456789&display=swap"]},"google":{"families":["Raleway:100,200,300,400,600,700"]}},
    },{
      plugin: require('/home/root404/web-dev/apps/gatsbyjs/centroarredamentomasanta/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
