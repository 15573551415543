// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-markdown-page-js": () => import("/home/root404/web-dev/apps/gatsbyjs/centroarredamentomasanta/src/templates/MarkdownPage.js" /* webpackChunkName: "component---src-templates-markdown-page-js" */),
  "component---src-pages-404-js": () => import("/home/root404/web-dev/apps/gatsbyjs/centroarredamentomasanta/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-appuntamenti-js": () => import("/home/root404/web-dev/apps/gatsbyjs/centroarredamentomasanta/src/pages/appuntamenti.js" /* webpackChunkName: "component---src-pages-appuntamenti-js" */),
  "component---src-pages-shop-online-js": () => import("/home/root404/web-dev/apps/gatsbyjs/centroarredamentomasanta/src/pages/shop-online.js" /* webpackChunkName: "component---src-pages-shop-online-js" */),
  "component---src-pages-index-js": () => import("/home/root404/web-dev/apps/gatsbyjs/centroarredamentomasanta/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

